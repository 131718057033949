//contentStepperId id in DOM, contentStepperContent array what you want to stepping (step + title + content + backGroundClass)
var contentStepper = function(contentStepperId, contentStepperContent) {
    var contentStepperId = contentStepperId;
    var contentStepperContent = contentStepperContent;

    var index = 0;
    var backGroundTempClass = "";

    function setContentStepper() {
        $("#" + contentStepperId + " .step-counter").text(contentStepperContent[index].step);
        $("#" + contentStepperId + " .step-title").text(contentStepperContent[index].title);
        var html_content = $.parseHTML(contentStepperContent[index].content);
        $("#" + contentStepperId + " .content-stepper-content").html(html_content);

        $("#" + contentStepperId).removeClass(backGroundTempClass);
        $("#" + contentStepperId).addClass(contentStepperContent[index].backGroundClass);
        backGroundTempClass = contentStepperContent[index].backGroundClass;
    }

    $("#" + contentStepperId + " .prev").click(function () {
        if (index != 0) {
            index = index - 1;
            setContentStepper(index);
        }
    });

    $('#' + contentStepperId + " .next").click(function () {
        if (index != contentStepperContent.length - 1) {
            index = index + 1;
            setContentStepper(index);
        }
    });

    setContentStepper();
}